
    .noData{
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    /deep/.el-breadcrumb {
        line-height: 40px;
    }
    .runPromotion{
        height: 100%;
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 30px;
            }
        }
            .examContent{
                margin-top: 20px;
                border: 1px solid #EEEEEE;
                color: #333333;
                font-size: 14px;
                font-weight: 500;
                .contentHeader{
                    display: flex;
                    background: #F2F3FA;
                    height: 60px;
                    line-height: 60px;
                    .contentItem:nth-child(1){
                        text-align: left;
                        padding-left: 26px;
                        width: 8%;
                    }
                    .contentItem:nth-child(2){
                        text-align: left;
                        width: 32%;
                    }
                    .contentItem:nth-child(3){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(4){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(5){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(6){
                        text-align: center;
                        width: 12%;
                    }
                    .contentItem:nth-child(7){
                        text-align: center;
                        width: 12%;
                    }
                }
                .contentBody{
                    display: flex;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 1px solid #EEEEEE;
                    .contentBodyItem:nth-child(1){
                        text-align: left;
                        padding-left: 26px;
                        width: 8%;
                        i{
                            cursor: pointer;
                            color: #7D7D7D;
                            font-size: 14px;
                            margin-right: 5px;
                        }
                    }
                    .contentBodyItem:nth-child(2){
                        text-align: left;
                        width: 32%;
                    }
                    .contentBodyItem:nth-child(3){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(4){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(5){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(6){
                        text-align: center;
                        width: 12%;
                    }
                    .contentBodyItem:nth-child(7){
                        text-align: center;
                        width: 12%;
                        span{
                            border: 1px solid #D1D2DB;
                            display: inline-block;
                            width: 68px;
                            height: 38px;
                            line-height: 38px;
                            border-radius: 5px;
                            padding-left: 14px;
                            margin-right: 6px;
                            text-align: left;
                        }
                    }
                    .fangKuang{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
                .contentFooter{
                    display: flex;
                    height: 80px;
                    line-height: 80px;
                    border-bottom: 1px solid #EEEEEE;
                    contentFooterItem{
                        padding: 0 20px;
                    }
                    .contentFooterItem:nth-child(1){
                        text-align: left;
                        padding-left: 26px;
                        width: 8%;
                    }
                    .contentFooterItem:nth-child(2){
                        text-align: left;
                        width: 32%;
                        span{
                            padding: 0 20px;
                        }
                    }
                    .contentFooterItem:nth-child(3){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(4){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(5){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(6){
                        text-align: center;
                        width: 12%;
                    }
                    .contentFooterItem:nth-child(7){
                        text-align: center;
                        width: 12%;
                        span{
                            border: 1px solid #D1D2DB;
                            display: inline-block;
                            width: 68px;
                            height: 38px;
                            line-height: 38px;
                            border-radius: 5px;
                            padding-left: 14px;
                            margin-right: 6px;
                            text-align: left;
                        }
                    }
                }
            }
        .storeMarketing{
            margin-top: 30px;
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .marketingHeader{
                display: flex;
                background: #F2F3FA;
                height: 60px;
                line-height: 60px;
                .marketingItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .marketingItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                }
            }
            .marketingBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .marketingBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .marketingBodyItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingBodyItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingBodyItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingBodyItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 82px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                    }
                }
            }
            .marketingFooter{
                .marketingFooter2{
                    display: flex;
                    height: 80px;
                    line-height: 80px;
                    border-bottom: 1px solid #EEEEEE;
                }
                .marketingFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .marketingFooterItem:nth-child(2){
                    text-align: left;
                    width: 12%;
                }
                .marketingFooterItem:nth-child(3){
                    text-align: left;
                    width: 29%;
                }
                .marketingFooterItem:nth-child(4){
                    text-align: left;
                    width: 27%;
                }
                .marketingFooterItem:nth-child(5){
                    text-align: left;
                    width: 22%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 82px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                    }
                }
            }
        }
        .boothAdvertising{
            margin-top: 30px;
            border: 1px solid #EEEEEE;
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            .contentHeader{
                display: flex;
                background: #F2F3FA;
                height: 60px;
                line-height: 60px;
                .contentItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .contentItem:nth-child(2){
                    text-align: left;
                    width: 28%;
                }
                .contentItem:nth-child(3){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(4){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(5){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(6){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(7){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(8){
                    text-align: center;
                    width: 8%;
                }
                .contentItem:nth-child(9){
                    text-align: center;
                    width: 10%;
                }
            }
            .contentBody{
                display: flex;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #EEEEEE;
                .contentBodyItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                    i{
                        cursor: pointer;
                        color: #7D7D7D;
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
                .contentBodyItem:nth-child(2){
                    text-align: left;
                    width: 28%;
                }
                .contentBodyItem:nth-child(3){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(4){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(5){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(6){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(7){
                    text-align: center;
                    width: 8%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
                .contentBodyItem:nth-child(8){
                    text-align: center;
                    width: 8%;
                }
                .contentBodyItem:nth-child(9){
                    text-align: center;
                    width: 10%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
            }
            .contentFooter{
                display: flex;
                height: 80px;
                line-height: 80px;
                border-bottom: 1px solid #EEEEEE;
                .contentFooterItem:nth-child(1){
                    text-align: left;
                    padding-left: 26px;
                    width: 8%;
                }
                .contentFooterItem:nth-child(2){
                    text-align: left;
                    width: 28%;
                }
                .contentFooterItem:nth-child(3){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(4){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(5){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(6){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(7){
                    text-align: center;
                    width: 8%;

                }
                .contentFooterItem:nth-child(8){
                    text-align: center;
                    width: 8%;
                }
                .contentFooterItem:nth-child(9){
                    text-align: center;
                    width: 10%;
                    span{
                        border: 1px solid #D1D2DB;
                        display: inline-block;
                        width: 68px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        padding-left: 14px;
                        margin-right: 6px;
                        text-align: left;
                    }
                }
            }
        }
    }
    .el-breadcrumb {
        line-height: 40px;
    }
    .boothAdvertising2{
        margin-top: 30px;
        border: 1px solid #EEEEEE;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        .contentHeader{
            display: flex;
            background: #F2F3FA;
            height: 60px;
            line-height: 60px;
            .contentItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .contentItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
        .contentBody{
            display: flex;
            height: 60px;
            line-height: 60px;
            border-bottom: 1px solid #EEEEEE;
            .contentBodyItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
                i{
                    cursor: pointer;
                    color: #7D7D7D;
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
            .contentBodyItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentBodyItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentBodyItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
        .contentFooter{
            display: flex;
            height: 80px;
            line-height: 80px;
            border-bottom: 1px solid #EEEEEE;
            .contentFooterItem:nth-child(1){
                text-align: left;
                padding-left: 26px;
                width: 8%;
            }
            .contentFooterItem:nth-child(2){
                text-align: left;
                width: 32%;
            }
            .contentFooterItem:nth-child(3){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(4){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(5){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(6){
                text-align: center;
                width: 12%;
            }
            .contentFooterItem:nth-child(7){
                text-align: center;
                width: 12%;
            }
        }
    }
